import {
  Button,
  Container,
  Dropdown,
  Image,
  Stack,
} from "react-bootstrap";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";

import NewPaginationControl from "../../components/controls_comp/control-Pagination";
import { useRef, useState, useEffect, useContext } from "react";

import {
  faSort,
  faSortAsc,
  faSortDesc,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import T from "../../common/traducao";
import ErrorIcon from "@mui/icons-material/Error";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { GetConfiguracoes, GetResponsaveis } from "../../config/localstorageConfig/localstorage";
import CloseIcon from '@mui/icons-material/Close';
import { SituacaoAssistencia, SituacaoVisitaTecnica, getStyleStatus } from "../../consts/situacao_assistencia";

import ResponsavelService from "../../services/service.responsavel";

const AssistenciasList = (props) => {
  const constantes = T();
  const {
    title,
    pages,
    records,
    data,
    buttons,
    columns,
    onSortSelect,
    onGetStyle,
    onPageSelect,
    noselect,
    sortname,
    sorttype,
    btntype,
    semcontainer,
    chipFilter,

  } = props;

  const [indexActive, setIndexActive] = useState();
  const [responsaveis, setResponsaveis] = useState();
  const currentsortname = useRef(sortname);
  const currentsorttype = useRef(sorttype);
  const [anchorEl, setAnchorEl] = useState(null);
  const [indexOrder, setIndexOrder] = useState();

  const [somenteVencidos, setSomenteVencidos] = useState(false);
  const [responsavelSelecionado, setResponsavelSelecionado] = useState("");

  const configGeral = GetConfiguracoes();

  const handleChangeSomenteVencidos = (event) => {
    setSomenteVencidos(event.target.checked);

  };
  const handleChangeResposavel = (event) => {
    setResponsavelSelecionado(event.target.value);
  };

  useEffect(() => {
    let filters = [];

    if (responsavelSelecionado) {
      filters.push(`ResponsavelId eq '${responsavelSelecionado}'`);
    }
    if (somenteVencidos) {
      const dataAtual = new Date().toISOString().split('T')[0];
      filters.push(`'${dataAtual}' gt PrazoResposta`);
    }
    const filter = {
      ...(filters.length > 0 && { filters: `${filters.join(' and ')}` }),
    };

    chipFilter && chipFilter(filter.filters);
  }, [somenteVencidos, responsavelSelecionado]);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchValidationResponsaveis = async () => {
      let responsaveis_list = GetResponsaveis()
      
      if(!responsaveis_list){
        await ResponsavelService.setCache();
        responsaveis_list = GetResponsaveis();
      }
      const reponsavel_solicitante = responsaveis_list?.find((item) => item?.Id === configGeral.ConfiguracaoAssistencia.ResponsavelSolicitanteId)
      setResponsaveis(reponsavel_solicitante ? [reponsavel_solicitante] : []);
    }
    
    fetchValidationResponsaveis()
  }, []);



  function getSwitchType(item, obj) {
    switch (item.type) {
      case "checkbox":
        return <input readonly type={"checkbox"} checked={obj}></input>;
      case "custom":
        return item.onGetCustom ? item.onGetCustom(obj) : obj;
      case "date":
        const data = new Date(obj);
        const dataformatada = data.toLocaleDateString("pt-BR", {
          timeZone: "UTC",
        });
        return dataformatada;
      case "image":
        return <Image width={100} src={obj ? obj : null} fluid />;
      default:
        return obj;
    }
  }

  function getMap(itemdata) {
    return columns.map((item) => {
      return {
        indexCampo: 0,
        indexDado: Object.keys(itemdata).findIndex(
          (element) => element === item.field
        ),
        key: item.key ?? null,
        type: item.type ?? null,
        digits: item.digits ?? null,
        align: item.align ?? null,
        onGetStyle: item.onGetStyle ?? null,
        onGetCustom: item.onGetCustom ?? null,
      };
    });
  }

  function GetSortType() {
    switch (currentsorttype.current) {
      case "sort":
        return "asc";
      case "asc":
        return "desc";
      case "desc":
        return "sort";
      default:
        return "sort";
    }
  }

  const GetHeader = (args) => {
    const { item, index } = args;

    const icon =
      currentsorttype.current === "sort"
        ? faSort
        : currentsorttype.current === "asc"
          ? faSortAsc
          : faSortDesc;
    const retorno = (

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "500px",
          overflowX: "auto",
        }}
      >

        <Box sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#dddddd",
          width: "112px",

          borderRadius: 10,
          padding: "0.5rem 0.6rem",
          gap: 1,

          "@media (max-width: 850px)": {
            width: "100px"
          }
        }}
          onClick={() => {
            setIndexOrder(index)


          }}
        >

          <Typography
            sx={{
              width: "100%",
              fontSize: "0.8rem",
              textAlign: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              "@media (max-width: 850px)": {
                fontSize: "0.7rem",
              }
            }}
          >
            {item.headerName}
          </Typography>

          {indexOrder === index && (
            <Button
              style={{
                background: "transparent",
                "&:hover": {
                  background: "transparent",

                },
                border: "none",
                padding: 0,
              }}
              onClick={(e) => {
                const sorttype = GetSortType();
                onSortSelect && onSortSelect(item.field, sorttype);
                currentsortname.current = item.field;
                currentsorttype.current = sorttype;
              }}
            >
              <FontAwesomeIcon
                icon={currentsortname.current === item.field ? icon : faSort}
                size={1}
                color="#515151"
              ></FontAwesomeIcon>
            </Button>
          )}

        </Box>
      </Box>
    );

    return onSortSelect ? retorno : item.headerName;
  };


  function Headers() {


    return (
      <Grid
        container
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ width: "100%" }}
        gap={1}
      >
        <Grid
          item
          xs={15}
          md={9}
          lg={7.8}
          xl={8.8}
          display={"flex"}
          alignItems={"center"}
          gap={1}
          sx={{ width: "100%" }}
        >
          <Typography
            fontSize={18}
            color={"#666363"}
            sx={{
              "@media (max-width: 550px)": {
                fontSize: 12,
              },
            }}
          >
            {constantes.Ordenarpor}:
          </Typography>

          <Box
            display={"flex"}
            alignItems={"center"}
            gap={1}
            sx={{
              overflowX: "auto",
              maxWidth: "100%",
              "@media (max-width: 800px)": {
                maxWidth: "82%",
              },
              "@media (max-width: 550px)": {
                maxWidth: "78%",
              },
              "@media (max-width: 400px)": {
                maxWidth: "70%",
              },
            }}
          >
            {data &&
              data.length > 0 &&
              columns &&
              columns.map((item, index) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      margin: "0",
                      justifyContent: "center",
                    }}
                  >
                    <Box display={"flex"} key={index} p={0}>
                      <GetHeader item={item} index={index} />
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Grid>

        <Grid
          item
          sx={{ margin: { xs: "5px 0" } }}
          xs={3}
          sm={3}
          md={1.96}
          lg={2}
          xl={1.15}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  value={somenteVencidos}
                  checked={somenteVencidos}
                  onChange={handleChangeSomenteVencidos}

                />

              }
              label={constantes.SomenteVencidos}
              labelPlacement="start"
              sx={{
                "& .MuiFormControlLabel-label": {
                  color: "#666666",
                  fontSize: 12,
                  "@media (max-width: 550px)": {
                    fontSize: 10,
                  },
                },
              }}
            />
          </FormGroup>
        </Grid>

        <Grid
          item
          sx={{ margin: { xs: "5px 0" } }}
          xs={6}
          sm={5}
          md={1.96}
          lg={2}
          xl={1.85}
        >
          <FormControl fullWidth>
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                color: "#8e8e8e",
                padding: 0,
                fontSize: "0.8rem",
                "&.Mui-focused": {
                  color: "#8e8e8e",
                },
              }}
            >
              {constantes.Responsavel}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Responsavel"
              value={responsavelSelecionado}
              onChange={handleChangeResposavel}
              endAdornment={
                responsavelSelecionado && (
                  <IconButton
                    aria-label="Clear Selection"
                    onClick={() => setResponsavelSelecionado(null)}
                    edge="end"
                    sx={{ margin: "1rem 1.5rem", }}
                    size="small"

                  >
                    <CloseIcon />
                  </IconButton>
                )
              }
              sx={{
                width: "100%",
                height: "2.9rem",
                display: "flex",
                padding: 0,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#d3d3d3",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#d3d3d3",
                },
                "&.Mui-focused": {
                  color: "green",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #d3d3d3",
                  borderColor: "#8e8e8e",
                },
              }}
            >
              {responsaveis?.map((item) => (
                <MenuItem key={item.Id} value={item.Id}>
                  {item.Nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
  const SearchControl = () => { };

  function getStyle(itemdata, i) {
    return indexActive === i
      ? noselect
        ? null
        : { background: "#017ebc", color: "white" }
      : onGetStyle
        ? onGetStyle(itemdata)
        : null;
  }

  function ButtonBodyTable(args) {
    const { itemdata, indexlinha } = args;

    let retorno = null;

    if (btntype && btntype === "dropdown") {
      const retorno_botoes = buttons?.map((btn, index) => {
        const isVisible = btn.isVisible ? btn.isVisible(itemdata) : true;
        const _retorno = isVisible && (
          <Dropdown.Item
            key={index}
            value={indexlinha}
            variant={btn.variant}
            onClick={(e) => {
              btn.onClick(e);
            }}
            style={{ width: "100%" }}
          >
            {btn.text}
          </Dropdown.Item>
        );
        return _retorno;
      });

      const handleStopPropagation = (e) => {
        e.stopPropagation();
      };

      retorno = retorno_botoes && retorno_botoes.length > 0 && (
        <Dropdown
          style={{ position: "inherit" }}
          onClick={handleStopPropagation}
        >
          <Dropdown.Toggle
            as={Button}
            variant="light"
            id="dropdown-basic"
            noCaret
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "55px",
              borderRadius: 25,
              margin: "0",
            }}
          >
            <MenuIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu>{retorno_botoes}</Dropdown.Menu>
        </Dropdown>
      );
    } else {
      let retorno_botoes =
        buttons &&
        buttons.map((btn, index) => {
          const isVisible = btn.isVisible ? btn.isVisible(itemdata) : true;
          const icon = btn.Icon ?? null;
          const btncalc = btn.getBtn ? (
            btn.getBtn(itemdata, indexlinha)
          ) : (
            <Button
              key={index}
              variant={btn.variant}
              onClick={btn.onClick}
              value={indexlinha}
            >
              {btn.text}
            </Button>
          );
          const _retorno = isVisible ? (
            <>
              {isVisible && " "}
              {isVisible && btncalc}
            </>
          ) : null;
          if (_retorno) {
            if (!retorno) {
              retorno = [];
            }
            retorno = retorno.concat(_retorno);
          }
          return _retorno;
        });
    }

    return retorno ? (
      <Stack direction="horizontal" gap={1}>
        {" "}
        {retorno}{" "}
      </Stack>
    ) : null;
  }
  // function getStyleStatus(item) {
  //   let situacao = item.Situacao;

  //   if (situacao === SituacaoAssistencia.RASCUNHO) {
  //     return { background: "#f7c16a", color: "black", width: "10px" };
  //   } else if (situacao === SituacaoAssistencia.NOVA) {
  //     return { background: "#008080", color: "white", width: "10px" };
  //   } else if (situacao === SituacaoAssistencia.EM_ANALISE) {
  //     return { background: "#754c75", color: "white", width: "10px" };
  //   } else if (situacao === SituacaoAssistencia.AGUARDANDO_ENVIO) {
  //     return { background: "#aa1430", color: "white", width: "10px" };
  //   } else if (situacao === SituacaoAssistencia.AGUARDANDO_RETORNO) {
  //     return { background: "#DA7B00", color: "white", width: "10px" };
  //   } else if (situacao === SituacaoAssistencia.AGUARDANDO_RESPOSTA) {
  //     return { background: "#448fb5", color: "white", width: "10px" };
  //   } else if (situacao === SituacaoAssistencia.REPROVADA) {
  //     return { background: "#4F0202", color: "white", width: "10px" };
  //   } else if (situacao === SituacaoAssistencia.CANCELADA) {
  //     return { background: "#593c31", color: "white", width: "10px" };
  //   } else if (situacao === SituacaoAssistencia.RETORNADA) {
  //     return { background: "#BAAA5D", color: "white", width: "10px" };
  //   } else if (situacao === SituacaoAssistencia.ENVIADA) {
  //     return { background: "#468A96", color: "white", width: "10px" };
  //   } else if (situacao === SituacaoAssistencia.APROVADA) {
  //     return { background: "#23b26d", color: "white", width: "10px" };
  //   } else if (situacao === SituacaoAssistencia.FINALIZADA) {
  //     return { background: "#3364BD", color: "white", width: "10px" };
  //   }
  //   return null;
  // }

  function getStyleBackgroundTicket(item) {

    let situacao = item.Situacao;

    if (situacao === SituacaoAssistencia.RASCUNHO) {
      return {
        background: "#efef97",
        color: "black",
        fontSize: "12px",
        width: "120px",
        overflow: "hidden",
        padding: "8px 4px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",

        "@media (max-width: 950px)": {
          padding: "8px 1px",
          width: "120px",
        },
        "@media (max-width: 550px)": {
          width: "85px",
        },
      };
    } else if (situacao === SituacaoAssistencia.NOVA) {
      return {
        background: "#afd4d6",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "120px",
        padding: "8px 4px",
        color: "black",
        "@media (max-width: 950px)": {
          padding: "8px 1px",
          width: "120px",
        },
        "@media (max-width: 550px)": {
          width: "85px",
        },
      };
    } else if (situacao === SituacaoAssistencia.EM_ANALISE) {
      return {
        background: "#5b355b",
        overflow: "hidden",
        whiteSpace: "nowrap",
        padding: "8px 4px",
        width: "120px",
        textOverflow: "ellipsis",
        maxWidth: "150px",
        color: "#f4f4f4",
        "@media (max-width: 950px)": {
          padding: "8px 1px",
          width: "120px",
        },
        "@media (max-width: 550px)": {
          width: "85px",
        },
      };
    } else if (situacao === SituacaoAssistencia.AGUARDANDO_ENVIO) {
      return {
        background: "#89081f",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "120px",
        padding: "8px 4px",
        textOverflow: "ellipsis",
        maxWidth: "150px",
        color: "#f4f4f4",
        "@media (max-width: 950px)": {
          padding: "8px 1px",
          width: "120px",
        },
        "@media (max-width: 550px)": {
          width: "85px",
        },
      };
    } else if (situacao === SituacaoAssistencia.AGUARDANDO_RETORNO) {
      return {
        background: "#ad6e22",
        color: "#f4f4f4",
        width: "120px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        padding: "8px 4px",
        textOverflow: "ellipsis",
        "@media (max-width: 950px)": {
          padding: "8px 1px",
          width: "120px",
        },
        "@media (max-width: 550px)": {
          width: "85px",
        },
      };
    } else if (situacao === SituacaoAssistencia.AGUARDANDO_RESPOSTA) {
      return {
        background: "#307699",
        color: "#f4f4f4",
        width: "120px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        padding: "8px 4px",
        textOverflow: "ellipsis",
        "@media (max-width: 950px)": {
          padding: "8px 1px",
          width: "120px",
        },
        "@media (max-width: 550px)": {
          width: "85px",
        },
      };
    } else if (situacao === SituacaoAssistencia.REPROVADA) {
      return {
        background: "#964646",
        color: "#f4f4f4",
        overflow: "hidden",
        padding: "8px 4px",
        textOverflow: "ellipsis",
        width: "120px",
        whiteSpace: "nowrap",
        "@media (max-width: 950px)": {
          padding: "8px 1px",
          width: "120px",
        },
        "@media (max-width: 550px)": {
          width: "85px",
        },
      };
    } else if (situacao === SituacaoAssistencia.CANCELADA) {
      return {
        background: "#7c5f53",
        color: "#f4f4f4",
        overflow: "hidden",
        padding: "8px 4px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "120px",
        "@media (max-width: 950px)": {
          padding: "8px 1px",
          width: "120px",
        },
        "@media (max-width: 550px)": {
          width: "85px",
        },
      };
    } else if (situacao === SituacaoAssistencia.RETORNADA) {
      return {
        background: "#918347",
        color: "#f4f4f4",
        overflow: "hidden",
        padding: "8px 4px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "120px",
        "@media (max-width: 950px)": {
          padding: "8px 1px",
          width: "120px",
        },
        "@media (max-width: 550px)": {
          width: "85px",
        },
      };
    } else if (situacao === SituacaoAssistencia.ENVIADA) {
      return {
        background: "#326670",
        color: "#f4f4f4",
        width: "120px",
        overflow: "hidden",
        padding: "8px 4px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        "@media (max-width: 950px)": {
          padding: "8px 1px",
          width: "120px",
        },
        "@media (max-width: 550px)": {
          width: "85px",
        },
      };
    } else if (situacao === SituacaoAssistencia.APROVADA) {
      return {
        background: "#1a8451",
        color: "#f4f4f4",
        overflow: "hidden",
        padding: "8px 4px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "120px",
        "@media (max-width: 950px)": {
          padding: "8px 1px",
          width: "120px",
        },
        "@media (max-width: 550px)": {
          width: "85px",
        },
      };
    } else if (situacao === SituacaoAssistencia.FINALIZADA) {
      return {
        background: "#adbcd3",
        color: "black",
        overflow: "hidden",
        whiteSpace: "nowrap",
        padding: "8px 4px",
        textOverflow: "ellipsis",
        width: "120px",
        "@media (max-width: 950px)": {
          padding: "8px 1px",
          width: "120px",
        },
        "@media (max-width: 550px)": {
          width: "85px",
        },
      };
    }
    return null;
  }
  const BodyTicketAssistencias = () => {
    return (
      data?.length !== 0 ?
        data?.map((item, index) => {
          const formatarData = (data) => {
            if (!data) return ""; // Trata caso a data seja nula ou indefinida
            const dataFormatada = new Date(data).toLocaleDateString("pt-BR", { timeZone: "UTC" });
            return dataFormatada;
          };

          const prazoRespostaFormatado = formatarData(item.PrazoResposta);
          const dataSolicitacao = formatarData(item.DataSolicitacao);
          const dataCadastro = formatarData(item.DataCadastro);

          let prazoResposta = item.PrazoResposta;

          let limitePrazo = new Date().toISOString().slice(0, 10);

          return (
            <Box
              display={"flex"}
              sx={{ flexDirection: "row", margin: "15px 0 " }}
            >
              <Box sx={getStyleStatus(item.Situacao)} />
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent="center"
                alignItems="center"
                gap={0.8}
                sx={getStyleBackgroundTicket(item)}
              >
                <Box
                  display="flex"
                  width={"100%"}
                >
                  <Typography
                    sx={{ fontSize: '12px' }}
                  >
                    {item.Situacao}
                  </Typography>
                </Box>

                <Box
                  display={"flex"}
                  width={"100%"}
                  ml={1}
                  flexDirection={"column"}
                >
                  <Typography
                    sx={{
                      fontSize: "0.78rem",
                      "@media (max-width: 550px)": {
                        fontSize: "0.58rem",
                      },
                    }}
                  >
                    {constantes.Assistencia}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                      "@media (max-width: 550px)": { fontSize: 10 },
                    }}
                  >
                    {item.Id}
                  </Typography>
                </Box>

                {item.Responsavel && (
                  <Box
                    display={"flex"}
                    width={"100%"}
                    ml={1}
                    alignItems={"flex-start"}
                    flexDirection={"column"}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.78rem",
                        "@media (max-width: 600px)": { fontSize: 8 },
                      }}
                    >
                      {constantes.Responsavel}
                    </Typography>
                    <Typography
                      sx={{
                        wordWrap: 'break-word', 
                        whiteSpace: 'normal', 
                        overflowWrap: 'break-word',
                        fontWeight: "bold",
                        fontSize: "0.95rem",
                        "@media (max-width: 550px)": { fontSize: 10 },
                      }}
                    >
                      {item.Responsavel !== "" ? item.Responsavel : "N/R"}
                    </Typography>
                  </Box>
                )}

                {item.PrazoResposta && (
                  <Box
                    display={"flex"}
                    width={"100%"}
                    ml={1}
                    flexDirection={"column"}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.78rem",
                        "@media (max-width: 550px)": { fontSize: 8 },
                      }}
                    >
                      {constantes.PrazoResposta}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "0.95rem",
                        "@media (max-width: 550px)": { fontSize: 10 },
                      }}
                    >
                      {prazoRespostaFormatado}
                    </Typography>
                  </Box>
                )}
              </Box>

              <Accordion
                aria-controls="panel1a-content"
                id="panel1a-header"
                TransitionProps={{ unmountOnExit: true }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                  "@media (max-width: 1366px)": {
                    width: "100%",
                  },
                  "@media (max-width: 1080px)": {
                    width: "100%",
                  },
                  "@media (max-width: 800px)": { width: "87%", },
                  "@media (max-width: 550px)": { width: "80%", },
                  "@media (max-width: 375px)": { width: "90%", },
                  "@media (max-width: 320px)": { width: "90%", },
                }}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "150px",

                    "@media (max-width: 1366px)": {
                      width: "100%",
                      height: "200px",
                    },
                    "@media (max-width: 800px)": {
                      width: "100%",
                      height: "205px",
                    },
                    "@media (max-width: 550px)": {
                      width: "100%",
                      height: "180px",
                    },
                    "@media (max-width: 375px)": {
                      width: "100%",
                      height: "200px",
                    },
                    "@media (max-width: 320px)": {
                      width: "100%",
                      height: "220px",
                    },
                  }}
                >
                  <Grid
                    container
                    sx={{
                      "@media (max-width: 550px)": { width: "75%" },
                    }}
                  >
                    <Grid
                      item
                      display={"flex"}
                      flexDirection={"column"}
                      xs={15}
                      sm={15}
                      md={4}
                      lg={4}
                      xl={5}
                      gap={0.6}
                    >
                      <Typography
                        sx={{
                          width: "100%",
                          flexShrink: 0,

                          "@media (max-width:1024px)": {
                            fontSize: 15,
                            width: "100%",
                          },
                          "@media (max-width: 800px)": {
                            fontSize: 14,
                            width: "62vw",
                          },
                          "@media (max-width: 550px)": {
                            fontSize: 11,
                            width: "68vw",
                          },
                          "@media (max-width: 375px)": {
                            fontSize: 10,
                            width: "50vw",
                          },
                          "@media (max-width: 320px)": {
                            fontSize: 10,
                            width: "50vw",
                          },
                        }}
                      >
                        <strong style={{ color: "#606060" }}>
                          {constantes.Cliente}
                        </strong>
                        : {item.IdCliente} | {item.NomeCliente}
                      </Typography>

                      <Typography
                        sx={{
                          color: "text.primary",
                          "@media (max-width:1024px)": {
                            fontSize: 14,
                            width: "62vw",
                          },
                          "@media (max-width: 850px)": {
                            fontSize: 14,
                          },
                          "@media (max-width: 550px)": {
                            fontSize: 11,
                            width: "55vw",
                          },
                          "@media (max-width: 320px)": {
                            fontSize: 10,
                            width: "100%",
                          },
                        }}
                      >
                        <strong style={{ color: "#606060" }}>
                          {constantes.DataSolicitacao}
                        </strong>
                        : {dataSolicitacao}
                      </Typography>
                      <Typography
                        sx={{
                          color: "text.primary",
                          "@media (max-width:1024px)": {
                            fontSize: 14,
                            width: "62vw",
                          },
                          "@media (max-width: 850px)": {
                            fontSize: 14,
                          },
                          "@media (max-width: 550px)": {
                            fontSize: 11,
                            width: "55vw",
                          },
                          "@media (max-width: 320px)": {
                            fontSize: 10,
                            width: "100%",
                          },
                        }}
                      >
                        <strong style={{ color: "#606060" }}>
                          {constantes.DataCadastro}
                        </strong>
                        : {dataCadastro}
                      </Typography>
                      {item.PrazoResposta < limitePrazo && (
                        <Typography
                          sx={{
                            color: "text.primary",
                            "@media (max-width:1024px)": {
                              fontSize: 14,
                              width: "62vw",
                            },
                            "@media (max-width: 850px)": {
                              fontSize: 15,
                            },
                            "@media (max-width: 550px)": {
                              fontSize: 11,
                              width: "55vw",
                            },
                            "@media (max-width: 320px)": {
                              fontSize: 10,
                              width: "100%",
                            },
                          }}
                        >
                          <strong style={{ color: "#bf2d2d" }}>
                            {constantes.MsgPrazoAssistenciaVencido}
                          </strong>
                        </Typography>
                      )}
                    </Grid>

                    <Grid
                      display={"flex"}
                      flexDirection={"column"}
                      xs={12}
                      md={4}
                      lg={4}
                      xl={5}
                    >
                      <Typography
                        sx={{
                          "@media (max-width:1024px)": {
                            fontSize: 14,
                            width: "62vw",
                          },
                          "@media (max-width: 820px)": {
                            width: "65vw",
                            fontSize: 14,
                          },
                          "@media (max-width: 550px)": {
                            fontSize: 12,
                            width: "55vw",
                          },
                          "@media (max-width: 320px)": {
                            fontSize: 10,
                            width: "100%",
                          },
                        }}
                      >
                        <strong style={{ color: "#606060" }}>
                          {constantes.NomeVendedor}
                        </strong>
                        : {item.IdVendedor} | {item.NomeVendedor}
                      </Typography>
                      <Typography
                        sx={{
                          color: "text.primary",
                          "@media (max-width:1024px)": {
                            fontSize: 14,
                            width: "62vw",
                          },
                          "@media (max-width: 850px)": {
                            fontSize: 14,
                          },
                          "@media (max-width: 550px)": {
                            fontSize: 11,
                            width: "55vw",
                          },
                          "@media (max-width: 320px)": {
                            fontSize: 10,
                            width: "100%",
                          },
                        }}
                      >
                        <strong style={{ color: "#606060" }}>
                          {constantes.NomeCausa}
                        </strong>
                        :{" "}
                        {item.IdCausa !== 0
                          ? `${item.IdCausa} | ${item.NomeCausa}`
                          : "N/C"}{" "}
                      </Typography>

                      <Typography
                        sx={{
                          color: "text.primary",
                          "@media (max-width:1024px)": {
                            fontSize: 14,
                            width: "62vw",
                          },
                          "@media (max-width: 850px)": {
                            fontSize: 14,
                          },
                          "@media (max-width: 550px)": {
                            fontSize: 11,
                            width: "55vw",
                          },
                          "@media (max-width: 320px)": {
                            fontSize: 10,
                            width: "100%",
                          },
                        }}
                      >
                        {item.SolicitarVisitaTecnica &&
                          <strong style={{ color: "#606060" }}>
                            {constantes.SolicitarAssistenciaTecnica}
                          </strong>
                        }
                        {item.SolicitarVisitaTecnica &&
                          <strong style={{ color: !item.SituacaoVisitaTecnica ? "#898305" : SituacaoVisitaTecnica.APROVADA ? "#3bbc75" : "#be4848" }}>
                            {item.SituacaoVisitaTecnica ?? "Sem Situacao"}
                          </strong>
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                      "@media (max-width: 520px)": { display: "none" },
                    }}
                  >
                    {prazoResposta < limitePrazo && (<ErrorIcon color="error" fontSize="medium" />)}
                    <ButtonBodyTable itemdata={item} indexlinha={index} />
                  </Box>
                </AccordionSummary>

                <AccordionDetails
                  sx={{
                    display: "flex",
                    "@media (max-width: 400px)": {
                      padding: 1,
                    },
                    "@media (max-width: 375px)": {
                      padding: 2,
                    },
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      display={"flex"}
                      flexDirection={"column"}
                      xs={12}
                      lg={4}
                      sx={{
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          width: "100%",
                          color: "text.primary",
                          "@media (max-width:1024px)": {
                            fontSize: 14,
                            width: "62vw",
                          },
                          "@media (max-width: 850px)": {
                            fontSize: 14,
                          },
                          "@media (max-width: 550px)": {
                            fontSize: 11,
                            padding: 0,
                            margin: 0,
                          },
                          "@media (max-width: 320px)": {
                            fontSize: 10,
                            width: "100%",
                          },
                        }}
                      >
                        <strong style={{ color: "#606060" }}>
                          {constantes.ObservacaoUsuario}
                        </strong>
                        : {item.ObservacaoUsuario}
                      </Typography>
                      <Box
                        sx={{
                          display: "none",
                          "@media (max-width: 520px)": {
                            display: "flex",
                            justifyContent: "flex-end",
                            margin: "5px 2px",
                          },
                          gap: 1

                        }}
                      >
                        {prazoResposta < limitePrazo && (<ErrorIcon color="error" fontSize="medium" sx={{ marginTop: "5px" }} />)}
                        <ButtonBodyTable itemdata={item} indexlinha={index} />
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box >
          );
        }) : (
          <Box sx={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center", margin: "10rem 0" }}>
            <Typography sx={{
              fontSize: {
                xs: "0.8rem",
                sm: "1rem",
                md: "1rem",
                lg: "1rem",
                xl: "1.2rem",
              },
              color: "#919191",
              fontWeight: "bold",
              textAlign: "center",
            }}>{constantes.MsgFiltroNaoEncontradoAssistencias}</Typography>
          </Box>
        )
    )
  };

  function Children() {

    return (
      <div>
        <h4>{title}</h4>
        <SearchControl />
        <Headers />
        <BodyTicketAssistencias />
        {data?.length !== 0 && (
          <NewPaginationControl
            pages={pages}
            records={records}
            onPageSelect={(number) => {
              onPageSelect && onPageSelect(number);
            }}
          />
        )}

      </div>
    );
  }

  return semcontainer ? Children() : <Container>{Children()}</Container>;
};

export default AssistenciasList;


