
import { createContext, useEffect, useState } from "react";
import T from "../common/traducao";
import { Form, Formik } from "formik";
import { Debug } from "../common/Debug";
import * as Yup from "yup";
import OrcamentoVendaService from "../services/service.orcamentovenda";
import { toast } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom';
import moment from "moment";
import { GetConfiguracoes, getAcessoSimulado } from "../config/localstorageConfig/localstorage";

export const OrcamentoVendaContext = createContext({
    orcamentoVenda: {},
    updateOrcamentoVenda: () => { },
    updateItens: (item, indexitem, setFieldValue) => { },
    updateItensImportados: (itens, setFieldValue) => { },
    validarOrcamento: (orcamento, setFieldValue, values) => { },
    updateTabelaFrete: () => { },
    updateTipoFrete: () => { },
    updateModoUsoFrete: () => { },
    agruparItens: () => { },
    deleteGroup: () => { },
    getOrcamento: () => { },
    updateDesconto1: (desconto, setFieldValue, values) => { },
    updateDesconto2: (desconto, setFieldValue, values) => { },
    updateDesconto3: (desconto, setFieldValue, values) => { },
    updateDesconto4: (desconto, setFieldValue, values) => { },
    updateDesconto5: (desconto, setFieldValue, values) => { },
    updateAcrescimo: (acrescimo, setFieldValue, values) => { },
    updateDescontoEspecial: (desconto, setFieldValue, values) => { },
    updateAcrescimoEspecial: (acrescimo, setFieldValue, values) => { },
    updateMunicipioEnderecoEntrega: (municipioEnderecoEntrega, setFieldValue, values) => { },
    updateDescontosEAcrescimos: (descontoseacrescimos, setFieldValue, values) => { },
    addCondicaoEspecial: (condicao, setFieldValue, values) => { },
    deleteCondicaoEspecial: (condicao, indexCondicaoEspecial, setFieldValue, values) => { },
    updateCliente: (cliente, setFieldValue, values) => { },
    updateOrcamentoVencido: (orcamento, setFieldValue, values) => { },
    updateLatitudeLongitudeEntrega: (orcamento, setFieldValue, values) => { },
});

const OrcamentoVendaContextProvider = ({ children }) => {


    const [orcamentoVenda, setOrcamentoVenda] = useState({});
    const [configs, setConfigs] = useState({});
    const [somenteLeitura, setSomenteLeitura] = useState(false);
    const [load, setLoad] = useState(false);
    const [erros, setErros] = useState();
    const [idOrcamentoDuplicado, setIdOrcamentoDuplicado] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [carregandoOrcamento, setCarregandoOrcamento] = useState(false);
    const [loadingError, setLoadingError] = useState();

    const [modalVencimentos, setModalVencimentos] = useState(false);
    const [modalItemFiname, setModalItemFiname] = useState(false);
    const [modalItem, setModalItem] = useState(false);
    const [modalImportarItens, setModalImportarItens] = useState(false);
    const [modalImportarItensPedido, setModalImportarItensPedido] = useState(false);
    const [modalDescontoEspecial, setModalDescontoEspecial] = useState(false);
    const [avisoImportarItens, setAvisoImportarItens] = useState(false);
    const [errosModalErrorsImportarItens, setErrosModalErrorsImportarItens] = useState(false);
    const [diferencaVencimentos, setDiferencaVencimentos] = useState(0);
    const [parcelas, setParcelas] = useState();
    const [prazoMedio, setPrazoMedio] = useState();

    const [orcamentoEdicao, setOrcamentoEdicao] = useState();
    const [orcamentoDuplicado, setOrcamentoDuplicado] = useState();
    const [descontosTabela, setDescontosTabela] = useState();
    const [exigirItensFiname, setExigirItensFiname] = useState(false);
    const [valorFreteCalculado, setValorFreteCalculado] = useState();
    const [recalculando, setRecalculando] = useState(false);
    const [finalizando, setFinalizando] = useState(false);
    const [salvando, setSalvando] = useState(false);
    const [modalMap, setModalMap] = useState(false);

    const [importandoItens, setImportandoItens] = useState(false);

    const [indexItemSelected, setIndexItemSelected] = useState(null);

    const [orcamentoVencido, setOrcamentoVencido] = useState(false);

    const [alertaLimitePorcentagemEspecial, setAlertaLimitePorcentagemEspecial] = useState(false)

    const [listagemValidacao, setListagemValidacao] = useState([]);

    const constantes = T();
    const vendedorSimulado = getAcessoSimulado();

    const params = useParams();

    const navigate = useNavigate();

    const configuracoes = GetConfiguracoes();

    const SchemaOrcamento = {

        OrcamentoVenda: Yup.object({
            Municipio: Yup.object({
                Id: Yup.string().required("Município Obrigatório"),
            }).required("Município Obrigatório"),
            TabelaPreco: Yup.object({
                Id: Yup.string().required("Tabela de Preço Obrigatório"),
            }).required("Tabela de Preço Obrigatório"),
            TabelaDesconto: Yup.object({
                Id: Yup.string().required("Tabela de Desconto Obrigatório"),
            }).required("Tabela de Desconto Obrigatório"),
            FormaPagamento: Yup.object({
                Id: Yup.string().required("Forma Pagamento Obrigatório"),
            }).required("Forma Pagamento Obrigatório"),
            TipoOperacao: Yup.string().required(
                "Operação de Venda é obrigatório"
            ),
            DataPrevisaoFaturamento: Yup.date().required("Previsão Faturamento Obrigatória"),
            TipoFrete: Yup.string().required("Tipo de Frete é obrigatório"),
            TipoContribuinte: Yup.string().when(["Cliente", "Municipio"], {
                is: (Cliente, Municipio) => {
                    console.log(Municipio)
                    return !Cliente && Municipio?.Estado?.Pais?.Sigla == 'BRA'
                },
                then: (schema) => schema.required("Tipo de Contribuinte é obrigatório"),
                // otherwise: (schema) => schema.required("Tipo de Contribuinte é obrigatório 2"),
            }),
            IndicadorIe: Yup.number().when(["Cliente", "Municipio"], {
                is: (Cliente, Municipio) => {
                    console.log(Municipio)
                    return !Cliente && Municipio?.Estado?.Pais?.Sigla == 'BRA'
                },
                then: (schema) => schema.moreThan(0, "Indicador IE é obrigatório").required("Indicador IE é obrigatório"),
                //otherwise: (schema) => schema.required("Tipo de Contribuinte é obrigatório 2"),
            }), Atributos: Yup.array().of(
                Yup.object().shape(
                    {
                        // Id: Yup.number().required('Required'),
                        Atributo: Yup.object({
                            Obrigatorio: Yup.boolean(),
                            // NomeExibicao : Yup.string().test(
                            //     {
                            //         name: 'atributo',
                            //         exclusive: false,
                            //         params: {},
                            //         message: (value, context) => {
                            //             console.log(value, context);
                            //             return `Atributo obrigatorio`
                            //         },
                            //         test: (value, context) => {
                            //             const valido = !context.parent.Atributo.Obrigatorio ||
                            //                 value;
                            //             console.log(value, context.parent, valido);
                            //             return valido;
                            //         }
                            //     }
                            // )
                        }),
                        // Valor: Yup.string().trim().test(
                        //     {
                        //         name: 'atributo',
                        //         exclusive: false,
                        //         params: {},
                        //         message: (value, context) => {
                        //             console.log(value, context, value.path);
                        //             return `Atributo obrigatorio`
                        //         },
                        //         test: (value, context) => {
                        //             const valido = !context.parent.Atributo.Obrigatorio ||
                        //                 value;
                        //             console.log(value, context.parent, valido);
                        //             return valido;
                        //         }
                        //     }
                        // )
                        Valor: Yup.string().trim().when(["Atributo"], {
                            is: (Atributo) => { return Atributo.Obrigatorio },
                            then: (schema) => {
                                return schema.required((value, context) => {
                                    return `Atributo Obrigatório`
                                })
                            },
                        })
                    }
                ),
            )
        }).required("Orçamento venda deve ser iniciado"),
    }

    async function getOrcamento(id) {
        setIsLoading(true);
        setCarregandoOrcamento(true);
        await OrcamentoVendaService.get(Number(id))
            .then((response) => {
                setLoadingError(null);
                updateOrcamentoVenda(response.data);
                setOrcamentoEdicao(response.data);
                (response.data.IdOrigem > 0) && setIdOrcamentoDuplicado(response.data.IdOrigem);
                return response.data;
            })
            .catch((error) => {
                handleError(error);
                setLoadingError(error);
            })
            .finally(() => {
                setIsLoading(false);
                setCarregandoOrcamento(false);
            });
    }

    useEffect(() => {
        params.id && getOrcamento(params.id);
        !params.id && setOrcamentoEdicao(null);

        params.idorigem && DuplicarOrcamentoVenda(params.idorigem);
        !params.idorigem && setOrcamentoDuplicado(null);

        params.idorigem && setIdOrcamentoDuplicado(params.idorigem);

    }, [])


    async function DuplicarOrcamentoVenda(id) {
        setIsLoading(true);
        setCarregandoOrcamento(true);
        await OrcamentoVendaService.DuplicarOrcamento(Number(id))
            .then((response) => {
                setLoadingError(null);
                updateOrcamentoVenda(response.data);
                setOrcamentoDuplicado(response.data);
                return response.data;
            })
            .catch((error) => {
                handleError(error);
                setLoadingError(error);
            })
            .finally(() => {
                setIsLoading(false);
                setCarregandoOrcamento(false);
            });

        setLoad(true);
    }


    const options_toast = {
        onClose: props => setErros(null),
    };

    function getDiferencaVencimentos(vencimentos, totais) {
        let total = 0;
        let totaldias = 0;
        vencimentos?.map((item, index) => {
            totaldias = totaldias + item.Dias;
            total = total + parseFloat(item.Valor);
        });
        return parseFloat((totais.ValorTotal - total).toFixed(2));
    }


    const updateOrcamentoVenda = (neworcamento) => {

        // const diferenca = getDiferencaVencimentos(neworcamento.Vencimentos, neworcamento.Totais);
        // setDiferencaVencimentos(diferenca > 0);

        setSomenteLeitura(neworcamento.Situacao !== "Rascunho");

        const descontosTabela = getDescontosTabela(neworcamento);
        setDescontosTabela(descontosTabela);
        setExigirItensFiname(neworcamento?.FormaPagamento?.ExigirItensFiname);
        setValorFreteCalculado(neworcamento?.ValorFreteCalculado);

        const disabled =
        {
            ModoUsoFrete: neworcamento?.TipoFrete === "9",
            FreteFixo: !configs.PermitirAlterarFreteVenda,
            ValorFrete: !configs.PermitirAlterarFreteVenda,
        }

        setConfigs(
            {
                ...neworcamento?.Configs,
                Disabled: disabled
            }
        );
        return setOrcamentoVenda(neworcamento);
    }

    // const calcularorcamentovenda = (orcamento) => {

    //     return OrcamentoVendaCalculo(orcamento);

    // }

    function handleError(error) {
        const _error =
            error.code === "ERR_NETWORK"
                ? constantes.ServerOffLine
                : error.response && error.response.data && error.response.data.error
                    ? error.response.data.error.message
                    : error.message;
        return setErros(_error);
    }

    useEffect(() => {
        erros && toast.error(`Erro : ${erros}`, options_toast);
    }, [erros])

    const newWeb = async (orcamento_base, setFieldValue, values) => {

        setRecalculando(true);
        await OrcamentoVendaService.new(orcamento_base)
            .then((response) => {
                updateOrcamentoVenda(response.data);
                setFieldValue("OrcamentoVenda", response.data);
                return response.data;
            })
            .catch((error) => {
                handleError(error);
            })
            .finally(() => {
                setRecalculando(false);
            });

    }

    const updateCliente = (cliente, setFieldValue, values) => {
        const orcamento = {
            ...values.OrcamentoVenda,
            Cliente: cliente,
            NomeCliente: cliente ? values.OrcamentoVenda.NomeCliente : "",
            CpfCnpj: cliente ? values.OrcamentoVenda.CpfCnpj : "",
            Telefone: cliente ? values.OrcamentoVenda.Telefone : "",
        }
        setFieldValue("OrcamentoVenda", orcamento);
        newWeb(orcamento, setFieldValue, values);
    }

    const updateOrcamentoVencido = (orcamento, setFieldValue, values) => {

        const novo_orcamento = {
            ...values.OrcamentoVenda,
            DataValidade: null
        }

        setFieldValue("OrcamentoVenda", novo_orcamento);
        updateCalculo(novo_orcamento, setFieldValue, values);
        //newWeb(orcamento, setFieldValue, values);
    }

    const eventUpdateCalculo = async (orcamento_base) => {
        return await OrcamentoVendaService.Calcular(orcamento_base);
    }

    const eventValidarItens = async (orcamento) => {
        return await OrcamentoVendaService.ValidarItens(orcamento);
    }

    const updateDesconto1 = (desconto, setFieldValue, values) => {
        const orcamento = {
            ...values.OrcamentoVenda,
            PercentualDesconto1Padrao: Number(desconto ?? 0)
        }
        setFieldValue("OrcamentoVenda", orcamento);
        updateCalculo(orcamento, setFieldValue, values);
    }

    const updateDesconto2 = (desconto, setFieldValue, values) => {
        const orcamento = {
            ...values.OrcamentoVenda,
            PercentualDesconto2Padrao: Number(desconto ?? 0)
        }
        setFieldValue("OrcamentoVenda", orcamento);
        updateCalculo(orcamento, setFieldValue, values);
    }

    const updateDesconto3 = (desconto, setFieldValue, values) => {
        const orcamento = {
            ...values.OrcamentoVenda,
            PercentualDesconto3Padrao: Number(desconto ?? 0)
        }
        setFieldValue("OrcamentoVenda", orcamento);
        updateCalculo(orcamento, setFieldValue, values);
    }

    const updateDesconto4 = (desconto, setFieldValue, values) => {
        const orcamento = {
            ...values.OrcamentoVenda,
            PercentualDesconto4Padrao: Number(desconto ?? 0)
        }
        setFieldValue("OrcamentoVenda", orcamento);
        updateCalculo(orcamento, setFieldValue, values);
    }

    const updateDesconto5 = (desconto, setFieldValue, values) => {
        const orcamento = {
            ...values.OrcamentoVenda,
            PercentualDesconto5Padrao: Number(desconto ?? 0)
        }
        setFieldValue("OrcamentoVenda", orcamento);
        updateCalculo(orcamento, setFieldValue, values);
    }

    const updateDescontoEspecial = (desconto, setFieldValue, values) => {
        const orcamento = {
            ...values.OrcamentoVenda,
            PercentualDescontoEspecialPadrao: Number(desconto ?? 0)
        }
        setFieldValue("OrcamentoVenda", orcamento);
        updateCalculo(orcamento, setFieldValue, values);
    }

    const updateAcrescimo = (acrescimo, setFieldValue, values) => {
        const orcamento = {
            ...values.OrcamentoVenda,
            PercentualAcrescimoPadrao: Number(acrescimo ?? 0)
        }
        setFieldValue("OrcamentoVenda", orcamento);
        updateCalculo(orcamento, setFieldValue, values);
    }

    const updateAcrescimoEspecial = (acrescimo, setFieldValue, values) => {
        const orcamento = {
            ...values.OrcamentoVenda,
            PercentualAcrescimoEspecialPadrao: Number(acrescimo ?? 0)
        }
        setFieldValue("OrcamentoVenda", orcamento);
        updateCalculo(orcamento, setFieldValue, values);
    }

    const updateDescontosEAcrescimos = (descontoseacrescimos, setFieldValue, values) => {

        const orcamento = {
            ...values.OrcamentoVenda,
            PercentualDesconto1Padrao: Number(descontoseacrescimos?.PercentualDesconto1Padrao ?? 0),
            PercentualDesconto2Padrao: Number(descontoseacrescimos?.PercentualDesconto2Padrao ?? 0),
            PercentualDesconto3Padrao: Number(descontoseacrescimos?.PercentualDesconto3Padrao ?? 0),
            PercentualDesconto4Padrao: Number(descontoseacrescimos?.PercentualDesconto4Padrao ?? 0),
            PercentualDesconto5Padrao: Number(descontoseacrescimos?.PercentualDesconto5Padrao ?? 0),
            PercentualDescontoEspecialPadrao: Number(descontoseacrescimos?.PercentualDescontoEspecialPadrao ?? 0),
            PercentualAcrescimoPadrao: Number(descontoseacrescimos?.PercentualAcrescimoPadrao ?? 0),
            PercentualAcrescimoEspecialPadrao: Number(descontoseacrescimos?.PercentualAcrescimoEspecialPadrao ?? 0),
        }
        setFieldValue("OrcamentoVenda", orcamento);
        updateCalculo(orcamento, setFieldValue, values);
    }

    const addCondicaoEspecial = (condicao, setFieldValue, values) => {
        const limitePercentualEspecial = configs.DescontoMaximoEspecial;
        const valorPercentualDescontoEspecial = condicao.PercentualDescontoEspecial;

        if (limitePercentualEspecial && (valorPercentualDescontoEspecial > limitePercentualEspecial)) {
            setAlertaLimitePorcentagemEspecial(true)
        } else {
            const orcamento = {
                ...values.OrcamentoVenda,
                CondicoesEspeciais: [
                    ...values.OrcamentoVenda.CondicoesEspeciais,
                    condicao
                ]
            }
            setAlertaLimitePorcentagemEspecial(false)
            setFieldValue("OrcamentoVenda", orcamento);
            updateCalculo(orcamento, setFieldValue, values);
            setModalDescontoEspecial(false);
        }
    }

    const deleteCondicaoEspecial = (condicao, indexCondicaoEspecial, setFieldValue, values) => {

        const condicoesespeciais = values.OrcamentoVenda.CondicoesEspeciais.filter(
            (item, i) => indexCondicaoEspecial !== i
        );

        const PercentualDescontoEspecialPadraoOriginal = values.OrcamentoVenda.PercentualDescontoEspecialPadrao;

        const orcamento = {
            ...values.OrcamentoVenda,
            CondicoesEspeciais: condicoesespeciais,
            PercentualDescontoEspecialPadrao: condicoesespeciais?.length > 0 ? PercentualDescontoEspecialPadraoOriginal : 0
        }

        setFieldValue("OrcamentoVenda", orcamento);
        updateCalculo(orcamento, setFieldValue, values);
    }


    const updateCalculo = async (orcamento_base, setFieldValue, values) => {
        setRecalculando(true);
        if (!carregandoOrcamento) {
            eventUpdateCalculo(orcamento_base)
                .then((response) => {
                    updateOrcamentoVenda(response.data);
                    setFieldValue("OrcamentoVenda", response.data);
                    return response.data;
                })
                .catch((error) => {
                    handleError(error);
                })
                .finally(() => {
                    setRecalculando(false);
                    setImportandoItens(false);
                });
        }
        return orcamento_base;
    }

    async function salvarOrcamento(orcamento_base) {

        if (finalizando) {
            return OrcamentoVendaService.AtualizarSituacao(orcamento_base)
        }

        return (orcamento_base.Id > 0)
            ? OrcamentoVendaService.put(orcamento_base.Id, orcamento_base)
            : OrcamentoVendaService.post(orcamento_base);
    }

    const updateTabelaPreco = (tabelapreco, setFieldValue, values) => {
        const orcamento = {
            ...orcamentoVenda,
            TabelaPreco: tabelapreco
        }
        setFieldValue("OrcamentoVenda", orcamento);
        updateOrcamentoVenda(orcamento);
    }

    const updateFormaPagamento = (formapagamento, setFieldValue, values) => {

        let vencimentos = values.OrcamentoVenda.Vencimentos;
        if (formapagamento.Vencimentos) {
            let somadias = 0;
            vencimentos = formapagamento.Vencimentos.map((item, i) => {
                somadias = somadias + item.Dias;
                const data = moment(values.OrcamentoVenda.DataPrevisaoFaturamento)
                    .add(item.Dias, "days")
                    .format("YYYY-MM-DD");
                return { Dias: item.Dias, Data: data, Valor: 0, Percentual: 0 };
            });
        }

        const orcamento = {
            ...values.OrcamentoVenda,
            ExigirItensFiname: formapagamento.ExigirItensFiname,
            FormaPagamento: formapagamento,
            CondicaoPagamento: formapagamento.Condicao,
            Vencimentos: vencimentos,
            Configs: {
                ...values.OrcamentoVenda.Configs,
                MaximoDiasVencimento: formapagamento.MaximoDiasVencimento,
                MinimoDiasVencimento: formapagamento.MinimoDiasVencimento,
            }
        }

        setFieldValue("OrcamentoVenda", orcamento);
        updateCalculo(orcamento, setFieldValue, values);
    }

    const updateTabelaFrete = (tabelafrete, setFieldValue, values) => {
        const orcamento = {
            ...values.OrcamentoVenda,
            TabelaFrete: tabelafrete
        }
        setFieldValue("OrcamentoVenda", orcamento);
        updateOrcamentoVenda(orcamento);
    }

    const updateTabelaDesconto = (tabeladesconto, setFieldValue, values) => {
        const orcamento = {
            ...values.OrcamentoVenda,
            TabelaDesconto: tabeladesconto
        }
        setFieldValue("OrcamentoVenda", orcamento);
        updateOrcamentoVenda(orcamento);
    }

    const updateVencimentos = (vencimentos, vencimentofixo, setFieldValue, values) => {
        const orcamentovendabase = values.OrcamentoVenda;

        const orcamento = {
            ...orcamentovendabase,
            Vencimentos: vencimentos,
            VencimentoFixo: vencimentofixo
        }
        setFieldValue("OrcamentoVenda", orcamento);
        updateCalculo(orcamento, setFieldValue, values);
    }

    const updateItensFiname = (itemfiname, indexitemfiname, setFieldValue, values) => {
        let itensfiname = [];
        if (Number(indexitemfiname) >= 0) {
            orcamentoVenda.ItensFiname[indexitemfiname] = itemfiname;
            itensfiname = orcamentoVenda.ItensFiname;
        } else {
            itensfiname =
                orcamentoVenda.ItensFiname
                    ? [...orcamentoVenda.ItensFiname, itemfiname]
                    : [itemfiname];
        }
        const orcamento = {
            ...orcamentoVenda,
            ItensFiname: itensfiname
        }
        setFieldValue("OrcamentoVenda", orcamento);
        updateOrcamentoVenda(orcamento);
    }

    const deleteItemFiname = (indexitemfiname, setFieldValue, values) => {

        const itensfiname = orcamentoVenda.ItensFiname.filter(
            (item, i) => indexitemfiname !== i
        );

        const orcamento = {
            ...values.OrcamentoVenda,
            ItensFiname: itensfiname
        }

        setFieldValue("OrcamentoVenda", orcamento);
        updateOrcamentoVenda(orcamento);
    }

    const updateItens = (item, indexitem, setFieldValue, values) => {

        const orcamentovendabase = values.OrcamentoVenda;

        const itemcalculo = {
            ...item,
            PercentualAcrescimoEspecialPraticado: Number(item.PercentualAcrescimoEspecialPraticado ?? 0),
            PercentualAcrescimoPraticado: Number(item.PercentualAcrescimoPraticado ?? 0),
            PercentualDesconto1Praticado: Number(item.PercentualDesconto1Praticado ?? 0),
            PercentualDesconto2Praticado: Number(item.PercentualDesconto2Praticado ?? 0),
            PercentualDesconto3Praticado: Number(item.PercentualDesconto3Praticado ?? 0),
            PercentualDesconto4Praticado: Number(item.PercentualDesconto4Praticado ?? 0),
            PercentualDesconto5Praticado: Number(item.PercentualDesconto5Praticado ?? 0),
            PercentualDescontoEspecialPraticado: Number(item.PercentualDescontoEspecialPraticado ?? 0)
        }

        let itens = [];
        if (Number(indexitem) >= 0) {
            orcamentovendabase.Itens[indexitem] = itemcalculo;
            itens = orcamentovendabase.Itens;
        } else {
            itens =
                orcamentovendabase.Itens
                    ? [...orcamentovendabase.Itens, itemcalculo]
                    : [itemcalculo];
        }
        const orcamento = {
            ...orcamentovendabase,
            Itens: listagemItensOrdenadaPorGrupo(itens)
        }
        setFieldValue("OrcamentoVenda", orcamento);
        updateCalculo(orcamento, setFieldValue, values);
    }

    const updateTipoFrete = (tipofrete, setFieldValue, values) => {

        const orcamentovendabase = values.OrcamentoVenda;

        const orcamento = {
            ...orcamentovendabase,
            TipoFrete: tipofrete
        }
        setFieldValue("OrcamentoVenda", orcamento);
        updateCalculo(orcamento, setFieldValue, values);
    }

    const updateModoUsoFrete = (modousofrete, setFieldValue, values) => {

        const orcamentovendabase = values.OrcamentoVenda;

        const orcamento = {
            ...orcamentovendabase,
            ModoUsoFrete: modousofrete
        }
        setFieldValue("OrcamentoVenda", orcamento);
        updateCalculo(orcamento, setFieldValue, values);
    }


    const validarOrcamento = (orcamento) => {
        eventValidarItens(orcamento)
            .then((response) => {
                const _listagem_validacoes = response.data.value.filter((item, index) => {
                    return item.Identificador === 'Item Venda'
                });
                setListagemValidacao(_listagem_validacoes);
            })
    }

    const updateItensImportados = (itens, setFieldValue, values) => {


        const orcamentovendabase = values.OrcamentoVenda;

        const tamanho = orcamentovendabase.Itens?.length;
        let itemid = tamanho + 1;


        const newitens = itens?.map((itemmap) => {
            const newitem = itemmap;
            newitem.Id = 0;
            newitem.Item = itemid;

            newitem.TabelaPreco = {
                Id: orcamentovendabase.TabelaPrecoId
            };
            newitem.Cfop = orcamentovendabase.Cfop;

            itemid++;

            newitem.Atributos = newitem.Atributos?.map((atributo_item) => {
                const newatributo = atributo_item;
                atributo_item.Id = 0;
                return newatributo;
            })

            return itemmap;
        });


        let novositens = [...orcamentovendabase.Itens, ...newitens];


        const orcamento = {
            ...orcamentovendabase,
            Itens: novositens
        }
        setImportandoItens(true);
        setRecalculando(true);
        eventUpdateCalculo(orcamento)
            .then((response) => {
                updateOrcamentoVenda(response.data);
                setFieldValue("OrcamentoVenda", response.data);
                validarOrcamento(response.data);
                return response.data;
            })
            .catch((error) => {
                handleError(error);
            })
            .finally(() => {
                setRecalculando(false);
                setImportandoItens(false);
            });
    }

    const updateLatitudeLongitudeEntrega = (latitude, longitude, setFieldValue, values) => {
        const orcamentovendabase = values.OrcamentoVenda;

        const orcamento = {
            ...orcamentovendabase,
            EnderecoEntrega: {
                ...orcamentovendabase.EnderecoEntrega,
                Latitude: latitude,
                Longitude: longitude,
            }
        }

        setFieldValue("OrcamentoVenda", orcamento);
    }

    const updateMunicipioEnderecoEntrega = (municipioEnderecoEntrega, setFieldValue, values) => {

        const orcamentovendabase = values.OrcamentoVenda;

        const orcamento = {
            ...orcamentovendabase,
            EnderecoEntrega: {
                ...orcamentovendabase.EnderecoEntrega,
                Municipio: municipioEnderecoEntrega
            }
        }

        setFieldValue("OrcamentoVenda", orcamento);
        updateCalculo(orcamento, setFieldValue, values);
    }

    const deleteItem = (indexitem, setFieldValue, values) => {

        const orcamentovendabase = values.OrcamentoVenda;

        const itens = listagemItensOrdenadaPorGrupo(orcamentovendabase.Itens.filter(
            (item, i) => indexitem !== i
        ));

        const orcamento = {
            ...orcamentovendabase,
            Itens: itens
        }

        setFieldValue("OrcamentoVenda", orcamento);
        updateCalculo(orcamento, setFieldValue, values);
    }

    function agruparItens(itens) {

        let lastgroup = "";
        return itens?.map((item, index) => {
            let group = null;
            if (lastgroup !== item.IdentificacaoProjeto) {
                lastgroup = item.IdentificacaoProjeto;
                group = item.IdentificacaoProjeto;
            }

            return {
                ...item,
                Item: index + 1,
                group: group
            }
        })
    }

    function listagemItensOrdenadaPorGrupo(itens) {
        return itens?.sort((item1, item2) => {
            return item1.IdentificacaoProjeto.toLowerCase().localeCompare(item2.IdentificacaoProjeto.toLowerCase());
        })
    }

    function deleteGroup(group, setFieldValue, values) {

        const orcamentovendabase = values.OrcamentoVenda;

        const itens = listagemItensOrdenadaPorGrupo(orcamentovendabase.Itens.filter(
            (item, i) => group !== item.IdentificacaoProjeto
        ));

        const orcamento = {
            ...orcamentovendabase,
            Itens: itens
        }

        setFieldValue("OrcamentoVenda", orcamento);
        updateOrcamentoVenda(orcamento);
    }

    function getDescontosTabela(orcamentobase) {
        return {
            PercentualAcrescimoPraticado: orcamentobase?.PercentualAcrescimoPraticado,
            PercentualAcrescimoTabela: orcamentobase?.PercentualAcrescimoTabela,
            PercentualAcrescimoEspecialTabela: orcamentobase?.PercentualAcrescimoEspecialTabela,
            PercentualDesconto1Praticado: orcamentobase?.PercentualDesconto1Praticado,
            PercentualDesconto1Tabela: orcamentobase?.PercentualDesconto1Tabela,
            PercentualDesconto2Praticado: orcamentobase?.PercentualDesconto2Praticado,
            PercentualDesconto2Tabela: orcamentobase?.PercentualDesconto2Tabela,
            PercentualDesconto3Praticado: orcamentobase?.PercentualDesconto3Praticado,
            PercentualDesconto3Tabela: orcamentobase?.PercentualDesconto3Tabela,
            PercentualDesconto4Praticado: orcamentobase?.PercentualDesconto4Praticado,
            PercentualDesconto4Tabela: orcamentobase?.PercentualDesconto4Tabela,
            PercentualDesconto5Praticado: orcamentobase?.PercentualDesconto5Praticado,
            PercentualDesconto5Tabela: orcamentobase?.PercentualDesconto5Tabela,
        };
    }

    function handleLoad(loading) {
        return setLoad(loading);
    }

    const handleToggleModalItem = () => {
        setModalItem(!modalItem)
    }
    return (
        <>
            <Formik
                validateOnMount={true}
                // validateOnChange={true}
                validateOnBlur={true}
                initialValues={{
                    OrcamentoVenda: {
                        Municipio: null,
                        TabelaPreco: null,
                        TabelaDesconto: null,
                        FormaPagamento: null,
                        TipoOperacao: "",
                        TipoFrete: "",
                        TipoContribuinte: "",
                        IndicadorIe: ""
                    }
                }}
                validationSchema={Yup.object().shape(SchemaOrcamento)}
                validate={(values) => {
                    const errors = {};
                    let validacoesitens = [];

                    if (values.OrcamentoVenda.Itens?.length > 0) {
                        for (const itemidx in values.OrcamentoVenda.Itens) {
                            const item = values.OrcamentoVenda.Itens[itemidx];
                            if ((item?.SituacaoProduto !== "Ativo")) {
                                validacoesitens = validacoesitens.concat(`Item (${item.Item}) ${item.NomeProduto} - ${item.SituacaoProduto}`)
                            }
                            if (item?.PrecoTabela === 0) {
                                validacoesitens = validacoesitens.concat(`Item (${item.Item}) ${item.NomeProduto} - sem preço de tabela`)
                            }
                            if (item?.PrecoVenda === 0) {
                                validacoesitens = validacoesitens.concat(`Item (${item.Item}) ${item.NomeProduto} - sem preço na tabela selecionada`)
                            }
                        }
                    }

                    if (values.OrcamentoVenda.ExigirItensFiname && values.OrcamentoVenda.ItensFiname?.length === 0) {
                        validacoesitens = validacoesitens.concat('Deve haver pelo menos 1 item finame')
                    }

                    if (values.OrcamentoVenda.Itens?.length === 0) {
                        validacoesitens = validacoesitens.concat('Deve haver pelo menos 1 item lancado')
                    }

                    if (validacoesitens.length > 0) {
                        errors.Validacoes = validacoesitens;
                    }

                    return errors;
                }}

                onSubmit={
                    (values, { resetForm, setSubmitting }) => {

                        const _diferencavencimentos = getDiferencaVencimentos(values.OrcamentoVenda.Vencimentos, values.OrcamentoVenda.Totais);
                        setDiferencaVencimentos(_diferencavencimentos !== 0);

                        if (_diferencavencimentos) {
                            setModalVencimentos(true);
                        } else {
                            setSubmitting(true);
                            setRecalculando(true);
                            setSalvando(true);
                            salvarOrcamento(values.OrcamentoVenda)
                                .then((response) => {
                                    toast.success(
                                        `Orçamento ${response.data.Id} salvo com sucesso !`
                                    );

                                    if (finalizando) {
                                        toast.success(
                                            `Orçamento ${response.data.Id} atualizado para o status ${response.data.Situacao}`
                                        )
                                    }

                                    setFinalizando(false);
                                    resetForm();
                                    navigate("/orcamentosvenda");
                                })
                                .catch((error) => {
                                    handleError(error);
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                    setRecalculando(false);
                                    setSalvando(false);
                                });
                        }
                    }}

            >
                {() => (
                    <>
                        <Form>
                            <OrcamentoVendaContext.Provider value={
                                {
                                    setModalImportarItens,
                                    setModalImportarItensPedido,
                                    setModalItem,
                                    setModalVencimentos,
                                    setModalItemFiname,
                                    setModalDescontoEspecial,
                                    updateOrcamentoVenda,
                                    updateTabelaPreco,
                                    updateCalculo,
                                    updateFormaPagamento,
                                    updateTabelaDesconto,
                                    updateVencimentos,
                                    updateItensFiname,
                                    updateItensImportados,
                                    validarOrcamento,
                                    updateTabelaFrete,
                                    updateTipoFrete,
                                    updateModoUsoFrete,
                                    deleteItemFiname,
                                    updateItens,
                                    deleteItem,
                                    deleteGroup,
                                    setDiferencaVencimentos,
                                    agruparItens,
                                    setFinalizando,
                                    getOrcamento,
                                    handleLoad,
                                    handleError,
                                    newWeb,
                                    updateMunicipioEnderecoEntrega,
                                    handleToggleModalItem,
                                    updateDesconto1,
                                    updateDesconto2,
                                    updateDesconto3,
                                    updateDesconto4,
                                    updateDesconto5,
                                    updateAcrescimo,
                                    updateDescontoEspecial,
                                    updateAcrescimoEspecial,
                                    updateDescontosEAcrescimos,
                                    addCondicaoEspecial,
                                    setAlertaLimitePorcentagemEspecial,
                                    deleteCondicaoEspecial,
                                    updateCliente,
                                    setIndexItemSelected,
                                    updateOrcamentoVencido,
                                    setOrcamentoVencido,
                                    setImportandoItens,
                                    setAvisoImportarItens,
                                    setErrosModalErrorsImportarItens,
                                    setParcelas,
                                    setPrazoMedio,
                                    setModalMap,
                                    updateLatitudeLongitudeEntrega,
                                    orcamentoVenda,
                                    configs,
                                    somenteLeitura,
                                    constantes,
                                    vendedorSimulado,
                                    modalVencimentos,
                                    modalItemFiname,
                                    modalDescontoEspecial,
                                    modalItem,
                                    modalImportarItens,
                                    modalImportarItensPedido,
                                    diferencaVencimentos,
                                    avisoImportarItens,
                                    errosModalErrorsImportarItens,
                                    parcelas,
                                    prazoMedio,
                                    listagemValidacao,
                                    alertaLimitePorcentagemEspecial,
                                    configuracoes,
                                    isLoading,
                                    descontosTabela,
                                    orcamentoEdicao,
                                    exigirItensFiname,
                                    valorFreteCalculado,
                                    recalculando,
                                    finalizando,
                                    loadingError,
                                    importandoItens,
                                    salvando,
                                    orcamentoDuplicado,
                                    idOrcamentoDuplicado,
                                    indexItemSelected,
                                    orcamentoVencido,
                                    modalMap
                                }}>
                                {children}
                            </OrcamentoVendaContext.Provider>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    )
}

export default OrcamentoVendaContextProvider;
